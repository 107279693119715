<script>
	export let count;
	export let name;

	let random = Math.random() * 360;

	let light = `hsla(${random}, 65%, 65%, 1)`;
	let dark = `hsla(${random}, 65%, 45%, 1)`;

	let color = count > 0 ? "#ffffff" : "#858585";
	let bgColor = count > 0 ? "#f5c13b" : "#ffffff";
</script>

<div class="container" style="--light-color: {light}; --dark-color: {dark}">
	<div class="name"><span class="text">{name}</span></div>
	<div class="inner-container">
		<div class="box" style="--box-color: {bgColor}">
			<span style="--text-color: {color}" class="count">{count}</span>
		</div>
	</div>
</div>

<style>
	.container {
		width: 100%;
		height: 100%;
		min-width: 176px;
		min-height: 50px;
		max-width: 320px;
		max-height: 95px;
		background: linear-gradient(90deg, var(--light-color) 0%, var(--dark-color) 100%);
		border-radius: 6px;
		display: flex;
		/* align-items: center; */
		justify-content: space-between;
		padding-left: 1.25rem;
		padding-right: 1.25rem;
		position: absolute;
	}

	.name {
		width: calc(65%);
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.text {
		width: calc(100%);
		display: inline-block;
		text-align: left;
		font-family: Pluto;
		font-style: normal;
		font-weight: bold;
		font-size: 0.78rem;
		line-height: 24px;
		color: #ffffff;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	/* .container:hover {
		cursor: pointer;
	} */

	.inner-container {
		display: flex;
		width: 30%;
		min-width: 36px;
		align-items: center;
		justify-content: center;
	}

	.box {
		width: 100%;
		height: 0;
		padding-bottom: calc(100% * 0.75);
		min-width: 36px;
		min-height: 27px;
		border-radius: 36px;
		background: var(--box-color);
		display: flex;
		align-items: center;
		/* position: relative; */
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	}

	.count {
		top: 0;
		bottom: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;

		font-family: Pluto;
		font-style: normal;
		font-weight: 900;
		font-size: 0.78rem;
		line-height: 24px;

		text-align: center;

		color: var(--text-color);
	}
</style>
