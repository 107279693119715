<script>
	import { onDestroy, onMount } from "svelte";
	import {
		dbGameSessionRounds,
		dbGameSessionRoundValue,
		dbHost,
		dbUsers,
		listenOnFirebaseKey,
		user,
	} from "../database";
	import { getParams, isEqual } from "../utils";
	import { myUserStore, usersStore, otherUsersStore } from "../store";
	import LoadingScreen from "./LoadingScreen.svelte";
	import LobbyScreen from "./LobbyScreen.svelte";
	import Question from "./QuestionScreen.svelte";
	import AnswerScreen from "./AnswerScreen.svelte";
	import WinnerScreen from "./WinnerScreen.svelte";

	let page; // To figure out what page to render
	let users = []; // Storing information about connected users

	let hostId; // Contains the ID of the host
	const isHost = getParams("isHost") === "true"; // Checks if the client is the host of the game via url queries
	// If the client is host then it sets itself as host inside the DB
	if (isHost) {
		dbHost.get().then(snap => {
			if (!snap.val()) {
				dbHost.set(user.id);
			}
		});
	}
	// This reactive statement keeps track of userId and hostId. If it sees that both the ID are same then it creates an event listener which will remove this client from Host if gets disconnected.
	$: {
		if (user.id === hostId) {
			dbHost.onDisconnect().remove();
		}
	}

	// This listener tracks who is currently the host and if the host has been changed.
	listenOnFirebaseKey(dbHost, val => {
		if (hostId) {
			const oldHostName = users.find(user => user.id === hostId)?.userName;
			const newHostName = users.find(user => user.id === val)?.userName;
			let message = "";
			if (hostId === user.id) {
				message = `${oldHostName || "Old Host"} has left the game and you are the new host`;
			} else {
				if (newHostName) {
					message = `${oldHostName || "Old Host"} has left the game and new host is ${newHostName}`;
				} else {
					message = `${oldHostName || "Old Host"} has left the game and new host has been assigned`;
				}
			}
			// info(message);
			console.log(message);
		}
		hostId = val;
	});

	// The function decides which page should be rendered
	function onPageChange(snap) {
		if (!snap.exists()) {
			page = "GAME_SCREEN";
			return;
		}
		page = snap.val();
	}

	let roundValue; // This variable store current round number
	var dbPageKey; // This variable store the current round's page

	// This listener listen to current round value and current round page from DB and pushes to client
	dbGameSessionRoundValue.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		roundValue = snap.val();
		if (dbPageKey) {
			dbPageKey.off("value", onPageChange); // Disconnecting below listener which was listening to a different page
		}
		dbPageKey = dbGameSessionRounds.child(roundValue).child("page"); // Reference to current round page
		dbPageKey.on("value", onPageChange); // Listening to a particular page
	});

	// This listener keeps track of users playing the game and updates the client side whenever there is any changes
	listenOnFirebaseKey(dbUsers, val => {
		// console.log("val", val);
		usersStore.set(val);
		// console.log("userstore", $usersStore);
		users = Object.values(val);
		// console.log("users", users);
		myUserStore.set(users.find(u => u.id === user.id));
		// console.log("myuserStore", $myUserStore);
	});

	let timer; // A variable to store the setInterval listener
	let oldOtherUserList = []; // A vaariable to figure out

	// When mounting the index.svelte that is loading the website for the first time we set an interval of 1 second where we try to check which players are online.
	onMount(() => {
		timer = setInterval(() => {
			const allOtherUsers = users.filter(u => u.id !== user.id); // We store all the users in the website except the client
			const otherUserList = [];
			// Then we segregate users depending on some condition and store it in otherUserList
			allOtherUsers.forEach(user => {
				if (user.online === true) {
					otherUserList.push({ ...user, online: true });
				} else if (typeof user.online === "number") {
					if (Date.now() - user.online > 5000) {
						otherUserList.push({ ...user, online: false });
					} else {
						otherUserList.push({ ...user, online: true });
					}
				} else {
					otherUserList.push({ ...user, online: false });
				}
			});
			// This compares if the new list is equel to old user list. If it is not then it changes stored value.
			if (!isEqual(otherUserList, oldOtherUserList)) {
				otherUsersStore.set(otherUserList);
				oldOtherUserList = otherUserList;
			}
		}, 1000);
	});
	// Destroying the interval when closing the tab
	onDestroy(() => {
		clearInterval(timer);
	});
</script>

{#if page === "GAME_SCREEN"}
	<LobbyScreen />
{:else if page === "QUESTION_SCREEN"}
	<Question />
{:else if page === "ANSWER_SCREEN"}
	<AnswerScreen />
{:else if page === "WINNER_SCREEN"}
	<WinnerScreen />
{:else}
	<LoadingScreen />
{/if}

<style>
	:global(*) {
		box-sizing: border-box;
	}
</style>
