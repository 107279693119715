export const params = new URLSearchParams(window.location.search);
export const getParams = function (name) {
    return "" + params.get(name);
};
export const getGameSessionId = function () {
    return `${getParams("roomId")}+${getParams("sessionId")}`;
};
export function isEqual(array1, array2) {
    return JSON.stringify(array1) === JSON.stringify(array2);
}
export const Confetti = (() => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.style.position = "fixed";
    canvas.style["z-index"] = 9000; // more than zindex of modals(3000) and everything
    canvas.style.top = "0";
    canvas.style.left = "0";
    canvas.style.width = "100vw";
    canvas.style.height = "100vh";
    canvas.style.pointerEvents = "none";
    const onResize = () => {
        if (canvas.parentElement !== null) {
            const b = canvas.getBoundingClientRect();
            canvas.width = b.width;
            canvas.height = b.height;
        }
    };
    window.addEventListener("resize", onResize);
    const instances = [];
    const configs = {
        size: {
            min: 10,
            max: 30,
        },
        speed: {
            min: 8,
            max: 10,
        },
        colors: ["#ee5555", "#ee8355", "#eecc55", "#55eec9", "#5592ee", "#bd55ee"],
        gravity: 0.1,
        getColor() {
            return this.colors[Math.floor(Math.random() * this.colors.length)];
        },
    };
    const range = (a, b = 0) => a + Math.random() * (b - a);
    const spawn = () => {
        const h = range(configs.size.min, configs.size.max);
        const speed = range(configs.speed.min, configs.speed.max);
        instances.push({
            x: range(canvas.width),
            y: -h,
            w: range(h / 4, h / 2),
            h: h,
            vx: range(-speed / 4, speed / 4),
            vy: range(speed / 2),
            angle: range(2 * Math.PI),
            color: configs.getColor(),
        });
    };
    let isSpawning = false;
    const run = () => {
        if (isSpawning) {
            spawn();
        }
        // render
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        for (let i = instances.length - 1; i >= 0; --i) {
            const cf = instances[i];
            ctx.save();
            ctx.translate(cf.x, cf.y);
            ctx.rotate(cf.angle);
            ctx.scale(Math.cos(cf.y * 0.015), 1);
            ctx.fillStyle = cf.color;
            ctx.fillRect(-cf.w / 2, -cf.h / 2, cf.w, cf.h);
            ctx.restore();
            cf.vy += configs.gravity;
            cf.x += cf.vx;
            cf.y += cf.vy;
            cf.angle += cf.vx * 0.1;
            if (cf.y > canvas.height + cf.h) {
                instances.splice(i, 1);
            }
        }
        if (canvas.parentElement !== null) {
            window.requestAnimationFrame(run);
        }
    };
    return {
        startSpawning() {
            if (!canvas.parentElement) {
                document.body.appendChild(canvas);
                onResize();
            }
            isSpawning = true;
            run();
        },
        stopSpawning() {
            isSpawning = false;
        },
        removeCanvas() {
            if (canvas.parentElement !== null) {
                instances.length = 0;
                document.body.removeChild(canvas);
            }
        },
    };
})();
