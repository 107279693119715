<script>
	import { onDestroy, onMount } from "svelte";
	import { dbHost, dbPage, dbData, dbQuestion, listenOnFirebaseKey } from "../database";
	import { myUserStore, usersStore, otherUsersStore } from "../store";
	import Coin from "../components/Coin.svelte";
	import PlayerBox2 from "../components/PlayerBox2.svelte";
	import WinnerButton from "../components/WinnerButton.svelte";

	let hostId;
	let myUser;
	let users;
	let question;
	let answers = {};
	let answersState = {};
	let revealedCount = 0;
	let otherUsers;
	let otherUsersCount;
	let isHost;
	let random = Math.random() * 360;

	let light = `hsla(${random}, 65%, 65%, 1)`;
	let dark = `hsla(${random}, 65%, 45%, 1)`;

	listenOnFirebaseKey(dbHost, val => (hostId = val));

	$: {
		otherUsers = $otherUsersStore;
		users = $usersStore;
		myUser = $myUserStore;
		otherUsersCount = otherUsers.length;
	}

	function changePage() {
		dbPage().set("WINNER_SCREEN");
	}

	onMount(() => {
		dbQuestion().once("value", snap => {
			if (snap.exists()) question = snap.val();
		});
		dbData().once("value", snap => {
			if (snap.exists()) {
				answers = snap.val();
				for (let [key, value] of Object.entries(answers)) {
					revealedCount++;
					// console.log(key, value);
					if (!answersState[value]) {
						answersState[value] = {
							count: 1,
							votedBy: [key],
						};
					} else {
						answersState[value].count++;
						answersState[value].votedBy.push(key);
					}
				}
			}
		});
	});
</script>

{#if question && hostId && myUser}
	<div class="container">
		<h1 class="heading">{question}</h1>
		<h2 class="reveal">{`Revealed ${revealedCount} of ${otherUsersCount + 1}`}</h2>
		<div class="inner-container">
			<ul class="list">
				{#if otherUsers}
					{#each otherUsers as user}
						{#if answersState[user.id]}
							<li><PlayerBox2 count={answersState[user.id].count} name={user.userName} /></li>
						{:else}
							<li><PlayerBox2 count={0} name={user.userName} /></li>
						{/if}
					{/each}
				{/if}
			</ul>
			<div class="user-zone">
				<div class="box" style="--light-color: {light}; --dark-color: {dark}">
					{#if answersState[myUser.id]}
						<div
							class="count-box"
							style="--box-color: {answersState[myUser.id].count > 0 ? '#f5c13b' : '#ffffff'}"
						>
							<span
								style="--text-color: {answersState[myUser.id].count > 0 ? '#ffffff' : '#858585'}"
								class="count">{answersState[myUser.id].count}</span
							>
						</div>
					{:else}
						<div class="count-box" style="--box-color: #ffffff">
							<span style="--text-color: #858585" class="count">0</span>
						</div>
					{/if}
					<span class="username">{myUser.userName}</span>
					<span class="message">Who do you think has voted you?</span>
				</div>
				{#if hostId === myUser?.id}
					<div class="btn" on:click={changePage}><WinnerButton disabled={myUser?.id !== hostId} /></div>
				{/if}
			</div>
		</div>
	</div>
{/if}
<div class="coin">
	<Coin />
</div>

<style>
	.container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.heading {
		font-family: Pluto;
		font-style: normal;
		font-weight: 900;
		font-size: 1.5rem;
		line-height: 24px;
		text-align: center;
		align-items: center;
		color: #ffffff;
		padding-top: 4rem;
	}

	.reveal {
		font-family: Pluto;
		font-style: normal;
		font-weight: normal;
		font-size: 1rem;
		line-height: 24px;
		text-align: center;
		color: #ffffff;
		opacity: 0.5;
		padding-top: 1.5rem;
	}

	.container .inner-container {
		display: flex;
		width: 90%;
		height: 75%;
		justify-content: center;
		margin: auto;
	}

	.list {
		width: 22%;
		padding-top: 1rem;
		display: flex;
		flex-direction: column;
	}

	li {
		width: 100%;
		height: 0;
		padding-bottom: calc(100% * 0.28);
		display: inline-block;
		min-width: 176px;
		min-height: 50px;
		border-radius: 6px;
		margin-bottom: 0.5rem;
		position: relative;
	}

	.user-zone {
		width: 78%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.box {
		width: 30%;
		height: 0;
		padding-bottom: calc(30% * 1.32);
		min-width: 227px;
		min-height: 300px;
		margin: 1rem;
		border: 2px solid #000000;
		border-radius: 6px;
		background: linear-gradient(180deg, var(--light-color) 0%, var(--dark-color) 100%);
		position: relative;
	}

	.btn {
		width: 30%;
		height: 10%;
		min-height: 60px;
		min-width: 200px;
		margin: 1rem;
	}

	.count-box {
		width: 15%;
		height: 0;
		padding-bottom: calc(15% * 0.75);
		margin-top: 0.75rem;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		min-width: 36px;
		min-height: 27px;
		border-radius: 36px;
		background: var(--box-color);
		display: flex;
		align-items: center;
		position: absolute;
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	}

	.count {
		top: 0;
		bottom: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;

		font-family: Pluto;
		font-style: normal;
		font-weight: 900;
		font-size: 0.78rem;
		line-height: 24px;

		text-align: center;

		color: var(--text-color);
	}

	.username {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 51px;
		min-height: 24px;
		font-family: Pluto;
		font-style: normal;
		font-weight: 900;
		font-size: 1.5rem;
		line-height: 30px;
		text-align: center;
		color: #ffffff;
	}

	.message {
		position: absolute;
		top: 68%;
		left: 50%;
		transform: translate(-50%, -42%);
		min-width: 136px;
		min-height: 34px;
		font-family: Pluto;
		font-style: normal;
		font-weight: normal;
		font-size: 0.8rem;
		line-height: 17px;
		text-align: center;
		color: #ffffff;
		opacity: 0.5;
	}

	.coin {
		position: absolute;
		right: 27px;
		top: 27px;
		z-index: 1;
	}
</style>
