<script>
	import { createEventDispatcher } from "svelte";
	export let user;
	export let disabled;

	const dispatch = createEventDispatcher();

	function sendUser() {
		dispatch("message", { ...user });
	}
</script>

<div {disabled} class:disabled class="container" on:click={sendUser}>
	<span>{user.userName}</span>
</div>

<style>
	.container {
		width: 100%;
		height: 0;
		padding-bottom: calc(100% * 0.24);
		min-width: 188px;
		min-height: 45px;
		display: flex;
		align-items: center;
		position: relative;
	}

	.container span {
		display: flex;
		bottom: 0;
		top: 0;
		position: absolute;
		align-items: center;
		padding-left: 1.7rem;
		font-family: Pluto;
		font-style: normal;
		font-weight: bold;
		font-size: 1rem;
		line-height: 24px;
		color: #ffffff;
	}

	.container:hover {
		cursor: pointer;
	}

	.disabled {
		pointer-events: none;
	}

	.disabled:hover {
		pointer-events: none;
	}
</style>
