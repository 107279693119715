<script>
	import { onDestroy, onMount } from "svelte";
	import { usersStore } from "../store";
	import Tooltip from "../components/Tooltip.svelte";
	import Connected from "../svg/Connected.svelte";
	import Disconnected from "../svg/Disconnected.svelte";
	import Connecting from "../svg/Connecting.svelte";
	export let user;
	export let hostId;
	let state;
	var timer;

	onMount(() => {
		timer = setInterval(() => {
			let onlineStatus = $usersStore[user.id].online;
			if (typeof onlineStatus === "number") {
				if (Date.now() - onlineStatus > 5000) {
					state = "offline";
				} else {
					state = "connecting";
				}
			} else {
				if (onlineStatus === true) {
					state = "online";
				} else {
					state = "offline";
				}
			}
		}, 1000);
	});

	onDestroy(() => {
		clearInterval(timer);
	});
</script>

<div class="container">
	<div class="hero">
		{user.userName.charAt(0)}
	</div>

	<span class="user-name">
		{user.userName}
		{#if user.id === hostId}
			(Host)
		{/if}
	</span>

	<div class="online ">
		<div class="tooltip">
			{#if state === "online"}
				<Tooltip title={state}>
					<Connected />
				</Tooltip>
			{:else if state === "connecting"}
				<Tooltip title={state}>
					<Connecting />
				</Tooltip>
			{:else if state === "offline"}
				<Tooltip title={state}>
					<Disconnected />
				</Tooltip>
			{/if}
		</div>
	</div>
</div>

<style>
	.container {
		display: flex;
		justify-content: space-between;
		padding-top: 1.5rem;
		padding-left: 1rem;
		padding-right: 1rem;
		align-items: center;
		text-align: center;
	}

	.hero {
		width: 25px;
		height: 25px;
		border-radius: 50%;
		background: #343e98;

		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 0.67rem;
		line-height: 16px;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;

		color: #ffffff;
	}

	.user-name {
		font-family: Pluto;
		font-style: normal;
		font-weight: bold;
		font-size: 0.78rem;
		line-height: 17px;
		padding-left: 0.8rem;

		color: #0c0030;
	}

	.online {
		margin-left: auto;
		height: 18px;
		width: 18px;
		border-radius: 0px;
	}
</style>
