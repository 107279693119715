<script>
	import { onMount } from "svelte";
	import { fetchNewData, getAllThemes } from "../../api";
	import { dbHost, dbTheme, dbThemeId, dbThemeData, listenOnFirebaseKey, user } from "../../database";
	import { getGameSessionId, getParams } from "../../utils";
	import InfiniteScroll from "../InfiniteScroll.svelte";

	let themes = [];
	let search = "";
	let next;
	let hostId;
	let theme;
	let loading = false;
	let filteredThemes = [];
	const isHost = getParams("isHost") === "true";

	const defaultTheme = {
		icon: "🤖",
		name: "Default theme",
		description: "Let us pick a theme for you!",
		th_id: "default",
	};

	listenOnFirebaseKey(dbHost, val => {
		hostId = val;
	});
	listenOnFirebaseKey(dbThemeData(), val => {
		theme = val;
	});

	onMount(async () => {
		loading = true;
		const [err, data] = await getAllThemes();
		loading = false;
		if (!err) {
			next = data.next;
			themes = [...themes, ...(data?.results || [])];
		}
	});
	$: {
		filteredThemes = themes;
		if (search.trim()) {
			const part = search.trim().toLowerCase();
			filteredThemes = themes.filter(
				theme => theme.name.toLowerCase().includes(part) || theme.description.toLowerCase().includes(part)
			);
		}
	}
	async function loadMoreThemes() {
		if (next && !loading) {
			loading = true;
			const [err, data] = await fetchNewData(next, { roomIdSessionId: getGameSessionId() });
			loading = false;
			if (!err) {
				next = data.next;
				themes = [...themes, ...(data?.results || [])];
			}
		}
	}

	async function onThemePick(item) {
		await dbThemeId().set(item.th_id);
	}

	function changeToThemePage() {
		window?.DIVE_APP?.changeToAppThemePage?.();
	}
</script>

<div class="game-themes">
	<div class="game-theme-dropdown">
		<div class="game-theme-button" class:game-theme-button--host={hostId === user.id}>
			{#if theme}
				{theme.icon}{" "}
				{theme.name}
			{:else}
				{defaultTheme.icon}{" "}
				{defaultTheme.name}
			{/if}
			{#if hostId === user.id}
				<img class="game-theme-drop-down-icon" src="/svg/dropdownIcon.svg" alt="drop down icon" />
			{/if}
		</div>
		<div class="game-theme-popup-container">
			<div class="game-theme-popup">
				<div class="game-theme-search">
					<input bind:value={search} class="game-theme-input" placeholder="Search Themes" />
				</div>
				<div class="game-theme-list">
					{#each filteredThemes as item}
						<div class="game-theme-item">
							<div class="game-theme-info">
								<div class="game-theme-item-icon">{item.icon}</div>
								<div class="game-theme-item-desc">
									<div class="game-theme-item-title">
										{item.name}
									</div>
									<div class="game-theme-item-subtitle">
										{item.description}
									</div>
								</div>
							</div>
							{#if theme?.th_id == item.th_id}
								<div class="game-theme-choose-button--selected game-theme-choose-button">
									CURRENT THEME
								</div>
							{:else}
								<!-- else content here -->
								<div class="game-theme-choose-button" on:click={() => onThemePick(item)}>PICK</div>
							{/if}
						</div>
					{/each}
					<InfiniteScroll hasMore={next} threshold={100} loadMore={loadMoreThemes} />
				</div>
				<div class="game-theme-list-button" on:click={changeToThemePage}>View all themes</div>
			</div>
		</div>
	</div>
	<div class="tooltip online-svg-tooltip">
		<div class="game-theme-icon">?</div>
		<div class="tooltiptext svg-tooltip">
			<div class="game-themes-header">🎨 Change theme</div>
			<div class="game-themes-desc">You can change the theme of the <br /> game by selecting the dropdown.</div>
		</div>
	</div>
</div>

<style>
	.game-theme-choose-button--selected {
		background: rgba(31, 23, 43, 0.1) !important;
		color: #1f172b50 !important;
		cursor: not-allowed !important;
	}
	.game-theme-list-button {
		cursor: pointer;
		background: #ffffff;
		color: #6c44a8;
		font-size: 14px;
		padding: 16px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid rgba(0, 0, 0, 0.2);
	}
	.game-theme-search {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.game-theme-input {
		border: 1px solid rgba(113, 96, 138, 0.5);
		border-radius: 43px;
		width: 100%;
		margin: 20px;
		padding: 8px 25px;
	}
	.game-theme-input::placeholder {
		color: #5e5e5e;
		opacity: 0.5;
		padding-left: 20px;
		margin: 0px 5px;
	}
	.game-theme-input:focus {
		outline: 1px solid rgba(113, 96, 138, 0.2);
	}
	.game-theme-choose-button {
		background: #efe8fa;
		color: #6c44a8;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 800;
		cursor: pointer;
		border-radius: 39px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px;
		margin: 6px 0 16px 0;
	}
	.game-theme-item-icon {
		font-size: 24px;
		padding-right: 10px;
	}
	.game-theme-info {
		display: flex;
	}
	.game-theme-item-title {
		color: #060d19;
		font-size: 14px;
		font-weight: bold;
	}
	.game-theme-item-subtitle {
		color: #636f84;
		font-size: 12px;
		font-weight: 500;
		margin-top: 2px;
	}
	.game-theme-list {
		padding: 0 20px;
		overflow: auto;
		max-height: 50vh;
	}
	.game-theme-popup {
		background: #ffffff;
		border-radius: 15px;
		min-width: 266px;
		overflow: hidden;
		filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
	}
	.game-theme-drop-down-icon {
		margin-left: 5px;
		max-height: 14px;
	}
	.game-theme-dropdown {
		margin-right: 10px;
	}
	.game-theme-button {
		background: #ffffff;
		border: 1px solid #71608a;
		border-radius: 43px;
		padding: 7px 15px;
		margin: 10px 0;
		max-width: 200px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.game-theme-popup-container {
		visibility: hidden;
		position: absolute;
		right: 10px;
		top: 30px;
		padding: 20px;
	}
	.game-theme-popup-container:hover {
		visibility: visible;
	}
	.game-theme-button--host:hover {
		cursor: pointer;
	}
	.game-theme-button--host:hover ~ .game-theme-popup-container {
		visibility: visible;
	}

	.game-themes-desc {
		color: #ffffffb2;
		font-weight: 700;
		font-size: 12px;
	}
	.game-themes-header {
		font-weight: 700;
		color: #ffffff;
		font-size: 14px;
	}
	.game-themes {
		font-family: Manrope;
		font-weight: 700;
		font-size: 14px;
		display: flex;
		align-items: center;
		position: relative;
	}
	.tooltip:hover .tooltiptext {
		visibility: visible;
	}
	.tooltiptext {
		border: 1px solid #473857;
		border-radius: 12px;
		box-shadow: 0px 0px 4px 2px rgba(255, 255, 255, 0.08);
		background: #1f172b;
		visibility: hidden;
		color: #757575;
		padding: 10px;
		border-radius: 6px;
		position: absolute;
		transform: translate3d(-92%, 14%, 0);
		white-space: nowrap;
		z-index: 1;
		box-shadow: 0px 0px 3px #8d8d8d;
	}
	@media screen and (max-width: 600px) {
		.tooltiptext {
			font-size: 8px;
		}
	}
	.online-svg-tooltip {
		display: flex;
		white-space: nowrap;
	}
	.tooltip {
		position: relative;
		display: inline;
	}
	.game-theme-icon {
		background-color: #473857;
		border: 1px solid #71608a;
		width: 20px;
		height: 20px;
		border-radius: 20px;
		color: white;
		font-weight: 700;
		font-size: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
