<script>
	import { onDestroy } from "svelte";

	export let threshold = 0;
	export let horizontal = false;
	export let elementScroll = undefined;
	export let hasMore = true;
	export let loadMore;

	let component;

	$: {
		if (component || elementScroll) {
			const element = elementScroll ? elementScroll : component.parentNode;

			element.addEventListener("scroll", onScroll);
			element.addEventListener("resize", onScroll);
		}
	}

	const onScroll = async e => {
		const element = e.target;

		const offset = horizontal
			? element.scrollWidth - element.clientWidth - element.scrollLeft
			: element.scrollHeight - element.clientHeight - element.scrollTop;

		if (offset <= threshold) {
			if (hasMore) {
				await loadMore();
			}
		}
	};

	onDestroy(() => {
		if (component || elementScroll) {
			const element = elementScroll ? elementScroll : component.parentNode;

			element.removeEventListener("scroll", null);
			element.removeEventListener("resize", null);
		}
	});
</script>

<div bind:this={component} style="width:0px" />
