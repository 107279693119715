import firebase from "firebase/app";
import "firebase/database";
import { getGameSessionId, getParams } from "./utils";

// Config for Firebase
const firebaseConfig = {
	apiKey: process.env.API_KEY,
	authDomain: process.env.AUTH_DOMAIN,
	projectId: process.env.PROJECT_ID,
	storageBucket: process.env.STORAGE_BUCKET,
	messagingSenderId: process.env.MESSAGING_SENDER_ID,
	appId: process.env.APP_ID,
};

firebase.initializeApp(firebaseConfig);

console.log(process.env);
// Setting up emulator mode
if (process.env.EMULATE) {
	const firebaseEmulators = {
		database: {
			host: "localhost",
			port: 9000,
		},
		functions: {
			host: "localhost",
			port: 5001,
		},
	};
	console.log("Automatically connecting Firebase SDKs to running emulators:");
	Object.keys(firebaseEmulators).forEach(function (key) {
		console.log("\t" + key + ": http://" + firebaseEmulators[key].host + ":" + firebaseEmulators[key].port);
	});
	if (firebaseEmulators.database && typeof firebase.database === "function") {
		firebase.database().useEmulator(firebaseEmulators.database.host, firebaseEmulators.database.port);
	}
	if (firebaseEmulators.firestore && typeof firebase.firestore === "function") {
		firebase.firestore().useEmulator(firebaseEmulators.firestore.host, firebaseEmulators.firestore.port);
	}
	if (firebaseEmulators.functions && typeof firebase.functions === "function") {
		firebase.functions().useEmulator(firebaseEmulators.functions.host, firebaseEmulators.functions.port);
	}
	if (firebaseEmulators.auth && typeof firebase.auth === "function") {
		firebase.auth().useEmulator("http://" + firebaseEmulators.auth.host + ":" + firebaseEmulators.auth.port);
	}
} else {
	console.log(
		"To automatically connect the Firebase SDKs to running emulators, replace '/__/firebase/init.js' with '/__/firebase/init.js?useEmulator=true' in your index.html"
	);
}

// Setting up base round
let roundValue = 1;

function getRoundValue() {
	return roundValue;
}

// Setting up references to multiple position inside the DB
export const dbRoot = firebase.database().ref("vtg"); // Reference to the root collection
export const dbQues = firebase.database().ref("vtgData"); // Reference to the questions
export const dbGameSession = dbRoot.child(getGameSessionId()); // Reference to a particular game session
export const dbGameSessionRoundValue = dbGameSession.child("roundValue"); // Reference to the current going on round
export const dbGameSessionRounds = dbGameSession.child("rounds"); // Reference to all the rounds that have taken place till now.
export const dbHost = dbGameSession.child("host"); // Reference to the host of the game
export const dbUsers = dbGameSession.child("users"); // Reference to the users inside that sessiom
export const dbUser = dbUsers.child(getParams("userId")); // Reference to the particular user in whose browser this code is running on.
export const dbThemeItem = dbGameSession.child("themeItem"); //Reference to a theme item
// Helper functions
export const dbGameSessionRound = () => dbGameSessionRounds.child(`${getRoundValue()}`); // Refers to current session's round value
export const dbPage = () => dbGameSessionRound().child("page"); // Refers to a round's current screen
export const dbQuestion = () => dbGameSessionRound().child("question"); // Refers to a round's game question
export const dbData = () => dbGameSessionRound().child("data"); // Refers to a round's game data
export const dbTheme = () => dbGameSession.child("theme"); // Refers to a round's theme
export const dbThemeId = () => dbGameSession.child("themeId"); // Refer to themeID of a game
export const dbThemeData = () => dbGameSession.child("themeData"); // Refer to theme data of a game

// Generating an user profile from the URL
export const user = {
	id: getParams("userId"),
	userName: getParams("userName"),
	profilePicture: getParams("userProfilePicture"),
};

// This listener listens on to different datapoint or collection depending on the page
export function listenOnFirebaseKey(firebaseKey, callback, notExitCallback) {
	Promise.resolve(roundTimeValuePromise).then(() => {
		firebaseKey.on("value", snap => {
			if (snap.exists()) {
				callback(snap.val());
			} else {
				notExitCallback && notExitCallback();
			}
		});
	});
}

// This promise help the above listener to check if the game has started or not
const roundTimeValuePromise = new Promise(resolve => {
	dbGameSessionRoundValue.once("value").then(snap => {
		if (!snap.exists()) {
			roundValue = 1;
		} else {
			roundValue = snap.val();
		}
		resolve(0);
	});
});

// This listener keeps track of current round value
dbGameSessionRoundValue.on("value", snap => {
	if (!snap.exists()) {
		dbGameSessionRoundValue.set(1);
		roundValue = 1;
		return;
	}
	roundValue = snap.val();
});

// Checking if an user is still inside the game or not
const connectedRef = firebase.database().ref(".info/connected");
connectedRef.on("value", snap => {
	if (snap.val() === true) {
		dbUser.update({
			online: true,
		});
		dbUser.onDisconnect().update({
			online: firebase.database.ServerValue.TIMESTAMP,
		});
	}
});

// Here we are updating the db with a user's information
dbUser.update(user);

// Listening to themeID changing in the game
window?.DIVE_APP?.listenOnAppThemeChange(e => {
	console.log(e);
	console.log(e.th_id, "listenOnAppThemeChange");
	dbThemeId().set(e.th_id);
});
