<script>
	import { onDestroy, onMount } from "svelte";
	export let user;
	export let answersState;
	export let winnerIds;

	let winner = false;
	let answerCount;

	import Crown from "../svg/Crown.svelte";

	var random = Math.random() * 360;

	var light = `hsla(${random}, 65%, 65%, 1)`;
	var dark = `hsla(${random}, 65%, 45%, 1)`;

	$: {
		if (answersState[user.id]) answerCount = answersState[user.id].count;
	}

	$: {
		for (let i = 0; i < winnerIds.length; i++) {
			const element = winnerIds[i];
			if (element === parseInt(user.id)) {
				winner = true;
			}
		}
	}
</script>

<div class="box" style="--light-color: {light}; --dark-color: {dark}">
	{#if answerCount}
		<div class="count-box" style="--box-color: {answerCount > 0 ? '#f5c13b' : '#ffffff'}">
			<span style="--text-color: {answerCount > 0 ? '#ffffff' : '#858585'}" class="count">{answerCount}</span>
		</div>
	{:else}
		<div class="count-box" style="--box-color: #ffffff">
			<span style="--text-color: #858585" class="count">0</span>
		</div>
	{/if}
	{#if winner}
		<div class="crown">
			<Crown />
		</div>
	{/if}
	<div class="username">{user.userName}</div>
</div>

<style>
	.box {
		width: 100%;
		height: 0;
		padding-bottom: calc(100% * 1.25);
		min-width: 100px;
		min-height: 150px;
		border: 2px solid #000000;
		border-radius: 6px;
		background: linear-gradient(180deg, var(--light-color) 0%, var(--dark-color) 100%);
		position: relative;
	}

	.count-box {
		width: 25%;
		height: 0;
		padding-bottom: calc(25% * 0.75);
		margin-top: 0.75rem;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		min-width: 36px;
		min-height: 27px;
		border-radius: 36px;
		background: var(--box-color);
		display: flex;
		align-items: center;
		position: absolute;
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	}

	.count {
		top: 0;
		bottom: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;

		font-family: Pluto;
		font-style: normal;
		font-weight: 900;
		font-size: 0.78rem;
		line-height: 24px;

		text-align: center;

		color: var(--text-color);
	}

	.crown {
		position: absolute;
		top: 35%;
		left: 50%;
		min-width: 36px;
		min-height: 27px;
		transform: translate(-50%, -50%);
	}

	.username {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 51px;
		min-height: 24px;
		font-family: Pluto;
		font-style: normal;
		font-weight: 900;
		font-size: 0.88rem;
		line-height: 24px;
		text-align: center;
		color: #ffffff;
	}
</style>
