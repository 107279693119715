import App from "./App.svelte";
import * as Sentry from "@sentry/browser";
if (process.env.APP_ENV === "prod") {
    Sentry.init({
        dsn: "https://ba77fa3b7f764f02a3a811042b48f29b@o422923.ingest.sentry.io/5956296",
        tracesSampleRate: 1.0,
    });
}
const app = new App({
    target: document.body,
});
export default app;
