<script>
	// your script goes here
	import { onDestroy, onMount } from "svelte";
	import { dbHost, dbQuestion, dbPage, listenOnFirebaseKey } from "../database";
	import { myUserStore, usersStore, otherUsersStore } from "../store";
	import GameThemes from "../components/GameThemes/index.svelte";
	import Playername from "../components/Playername.svelte";
	import Button from "../components/Button.svelte";
	import Coin from "../components/Coin.svelte";

	let hostId;
	let otherUsers = [];
	let myUser;
	let onlineUsers;

	listenOnFirebaseKey(dbHost, val => (hostId = val));

	$: {
		otherUsers = $otherUsersStore;
		// console.log(otherUsers);
		onlineUsers = otherUsers.filter(user => user.online);
		// console.log("online", onlineUsers);
	}

	$: {
		myUser = $myUserStore;
		// console.log("myuser", myUser);
	}

	function onButtonClick() {
		if (onlineUsers.length >= 2) {
			// dbQuestion().set("Who gives the most awkard hug?");
			dbPage().set("QUESTION_SCREEN");
		}
	}
</script>

<div class="container">
	<div class="heading"><span class="font">Most likely to</span></div>

	<div class="waiting">
		<span class="font">Waiting for others to join...</span>
	</div>

	<div class="wrapper">
		<ul class="list">
			{#if myUser}
				<li><Playername user={myUser} {hostId} /></li>
			{/if}
			{#each otherUsers as user}
				<li><Playername {user} {hostId} /></li>
			{/each}
		</ul>
	</div>

	<div class="btn" on:click={onButtonClick}>
		<Button disabled={onlineUsers.length < 2} />
	</div>
</div>

<div class="coin">
	<Coin />
</div>

<div class="game-themes">
	<GameThemes />
</div>

<style>
	.container {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 2rem;
	}

	.font {
		width: 100%;
		height: 100%;
		min-width: 243px;
		min-height: 20px;
		font-family: Pluto;
		font-style: normal;
		font-weight: 900;
		font-size: 1rem;
		line-height: 20px;

		text-align: center;

		color: #ffffff;
	}

	.heading {
		width: 30%;
		height: 10%;
		margin-top: 0.5rem;
		margin-bottom: 1rem;
	}

	.waiting {
		width: 30%;
		height: 10%;

		padding-top: 1.5em;
	}

	.wrapper {
		width: 30%;
		height: 100%;
		min-width: 284px;
		min-height: 266px;
		max-width: 400px;
		max-height: 500px;

		background: #ffffff;
		border-radius: 15px;

		margin-top: 1rem;
		margin-bottom: 2.5rem;
	}

	.list::-webkit-scrollbar {
		width: 8px;
		height: 122px;
	}

	.list::-webkit-scrollbar-thumb {
		background: #2a337e;
		border-radius: 15px;
	}

	.list::-webkit-scrollbar-track {
		background: transparent;
		margin-right: 10px;
	}

	.list::-webkit-scrollbar-track-piece:end {
		background: transparent;
		margin-bottom: 10px;
	}

	.list::-webkit-scrollbar-track-piece:start {
		background: transparent;
		margin-top: 10px;
	}

	.list {
		width: 100%;
		height: 100%;
		min-width: 274px;
		min-height: 266px;
		overflow-x: hidden;
		overflow-y: scroll;

		padding-bottom: 1rem;
	}

	.btn {
		width: 20%;
		height: 8%;
		min-height: 60px;
		min-width: 200px;
	}

	.coin {
		position: absolute;
		right: 27px;
		top: 27px;
		z-index: 1;
	}

	.game-themes {
		position: absolute;
		right: 100px;
		top: 27px;
		z-index: 1;
	}
</style>
