<script>
	import { onDestroy, onMount, afterUpdate } from "svelte";
	import { dbHost, dbPage, dbData, dbQuestion, listenOnFirebaseKey, dbGameSessionRoundValue } from "../database";
	import { myUserStore, usersStore, otherUsersStore } from "../store";
	import { Confetti } from "../utils";
	import { setRandomThemeItem } from "../api";
	import Box from "../components/Box.svelte";
	import NewTurnButton from "../components/NewTurnButton.svelte";
	import Coin from "../components/Coin.svelte";

	let hostId;
	let myUser;
	let users;
	let question;
	let answers = {};
	let answersState = {};
	let winner;
	let winnerIds = [];
	let otherUsers;
	let otherUsersCount;
	let isHost;
	let revealedCount;
	let roundValue;
	var confettiTimeOut;

	listenOnFirebaseKey(dbHost, val => (hostId = val));

	dbGameSessionRoundValue.once("value", snap => {
		if (snap.exists()) {
			roundValue = snap.val();
		}
	});

	$: {
		otherUsers = $otherUsersStore;
		users = $usersStore;
		myUser = $myUserStore;
		otherUsersCount = otherUsers.length;
		// console.log(users);
	}

	$: {
		if (winnerIds.length === 1) winner = users[winnerIds[0]]?.userName;
	}

	async function onButtonClick() {
		try {
			const content = await setRandomThemeItem();
			dbGameSessionRoundValue.set(roundValue + 1);
		} catch (error) {
			console.error(error);
		}
	}

	onMount(() => {
		// console.log("inside winner screen -", typeof winnerIds);
		dbQuestion().once("value", snap => {
			if (snap.exists()) question = snap.val();
		});
		dbData().once("value", snap => {
			if (snap.exists()) {
				answers = snap.val();
				for (let [key, value] of Object.entries(answers)) {
					revealedCount++;
					// console.log(key, value);
					if (!answersState[value]) {
						answersState[value] = {
							count: 1,
							votedBy: [key],
						};
					} else {
						answersState[value].count++;
						answersState[value].votedBy.push(key);
					}
				}
				let maxScore = 0;
				// let tempWinnderIds = [];
				for (let [key, value] of Object.entries(answersState)) {
					if (value.count > maxScore) maxScore = value.count;
				}
				for (const key in answersState) {
					if (answersState[key].count == maxScore) {
						// winnerIds.push(parseInt(key));
						winnerIds = [...winnerIds, parseInt(key)];
						console.log(winnerIds);
					}
				}
			}
		});
		Confetti.startSpawning();
		console.log("1");
		clearTimeout(confettiTimeOut);
		confettiTimeOut = setTimeout(function () {
			Confetti.stopSpawning();
			Confetti.removeCanvas();
		}, 5000);
	});
</script>

<div class="container">
	<h1 class="heading">{question}</h1>
	{#if winner}
		<h2 class="reveal">{winner} is the winner!</h2>
	{:else}
		<h2 class="reveal">It’s a tie!</h2>
	{/if}
	<div class="inner-container">
		<ul class="list">
			{#if users && winnerIds}
				{#each Object.values(users) as user}
					<li><Box {user} {answersState} {winnerIds} /></li>
				{/each}
			{/if}
		</ul>
	</div>
	{#if hostId === myUser?.id}
		<div class="btn" on:click={onButtonClick}><NewTurnButton /></div>
	{:else}
		<h2 class="reveal">Wait for the host to start the next turn!</h2>
	{/if}
</div>

<div class="coin">
	<Coin />
</div>

<style>
	.container {
		width: 100%;
		/* height: 100%; */
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.heading {
		font-family: Pluto;
		font-style: normal;
		font-weight: 900;
		font-size: 1.5rem;
		line-height: 24px;
		text-align: center;
		align-items: center;
		color: #ffffff;
		padding-top: 4rem;
	}

	.reveal {
		font-family: Pluto;
		font-style: normal;
		font-weight: normal;
		font-size: 1rem;
		line-height: 24px;
		text-align: center;
		color: #ffffff;
		opacity: 0.5;
		padding-top: 1.5rem;
	}

	.inner-container {
		display: flex;
		flex-direction: column;
		width: 80%;
		height: 65%;
		justify-content: center;
		align-items: center;
	}

	.list {
		width: 75%;
		padding-top: 1rem;
		display: flex;
		flex-wrap: wrap;
		align-content: baseline;
	}

	li {
		width: 22%;
		display: inline-block;
		height: 0;
		padding-bottom: calc(22% * 1.25);
		min-width: 100px;
		min-height: 150px;
		border-radius: 6px;
		margin-bottom: 0.75rem;
		margin-left: 0.75rem;
		position: relative;
	}

	.btn {
		width: 20%;
		height: 13%;
		min-height: 60px;
		min-width: 200px;
		margin: 1rem;
	}

	.coin {
		position: absolute;
		right: 27px;
		top: 27px;
		z-index: 1;
	}
</style>
