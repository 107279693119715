<script>
	export let disabled;
	console.log("dis", disabled);
</script>

<button class="btn" {disabled} class:disabled>
	<span class="text">View Winner</span>
</button>

<style>
	.btn {
		height: 100%;
		width: 100%;
		border-radius: 30px;

		min-height: 60px;
		min-width: 200px;

		background: #ffffff;
		box-shadow: inset 0px -9px 0px #cacaca;
	}

	.text {
		width: 99px;
		height: 24px;
		left: calc(50% - 99px / 2 - 15.5px);
		top: calc(50% - 24px / 2 - 3px);

		font-family: Pluto;
		font-style: normal;
		font-weight: 900;
		font-size: 1rem;
		line-height: 24px;
		text-align: center;

		color: #4c45ac;
	}

	.disabled {
		-webkit-text-stroke: 0px;
		cursor: not-allowed;
		background: #ffffff;
		opacity: 0.5;
		box-shadow: inset 0px -9px 0px #cacaca;
	}
</style>
