<script>
	// your script goes here
	import { dbHost, dbQuestion, dbData, dbPage, listenOnFirebaseKey } from "../database";
	import { myUserStore, usersStore, otherUsersStore } from "../store";
	import ConfirmButton from "../components/Confirm-Button.svelte";
	import ContinueButton from "../components/ContinueButton.svelte";
	import Playerbox from "../components/Playerbox.svelte";
	import Coin from "../components/Coin.svelte";
	import Card from "../svg/Card.svelte";

	let hostId;
	let otherUsers = [];
	let myUser;
	let onlineUsers;
	let clickedId;
	let choice = "Confirm Vote";
	let vote;
	let voted = false;
	let question;
	let fontSize;
	let fontWeight;

	listenOnFirebaseKey(dbHost, val => (hostId = val));
	listenOnFirebaseKey(dbQuestion(), val => (question = val));

	$: {
		otherUsers = $otherUsersStore;
		// console.log("others", otherUsers);
		onlineUsers = otherUsers.filter(user => user.online);
		// console.log("online", onlineUsers);
	}

	$: {
		myUser = $myUserStore;
		console.log("myuser", myUser);
	}

	$: {
		fontSize = question && question.length > 50 ? "0.75rem" : "1rem";
		fontWeight = question && question.length > 50 ? "500" : "900";
		console.log(fontSize, fontWeight);
	}
	function handleMessage(event) {
		console.log(event.detail);
		clickedId = event.detail.id;
		vote = event.detail;
		voted = true;
	}

	function handleConfirm() {
		if (voted) {
			choice = "Confirmed";
			dbData().child(myUser.id).set(vote.id);
		}
	}

	function changePage() {
		dbPage().set("ANSWER_SCREEN");
	}
</script>

{#if question && hostId}
	<div class="container">
		<h1 class="heading">Pick a player to vote</h1>
		<div class="inner-container">
			<div class="vote">
				<div class="card">
					<div class="card-svg">
						<Card />
					</div>
					<div class="question-wrapper">
						<span class="question" style="--font-size: {fontSize}; --font-weight: {fontWeight}"
							>{question}</span
						>
					</div>
				</div>
				<div class="btn" on:click={handleConfirm}>
					<ConfirmButton {choice} disabled={choice == "Confirmed" || voted === false} />
				</div>
				{#if hostId === myUser?.id}
					<div class="btn" on:click={changePage}>
						<ContinueButton disabled={!voted || choice !== "Confirmed"} />
					</div>
				{/if}
			</div>
			<ul class="list">
				{#if otherUsers}
					{#each otherUsers as user}
						<li class={clickedId === user.id ? "picked" : "item"} class:block={choice == "Confirmed"}>
							<Playerbox disabled={choice == "Confirmed"} {user} on:message={handleMessage} />
						</li>
					{/each}
					{#if otherUsers.length % 2 != 0}
						<li />
					{/if}
				{/if}
			</ul>
		</div>
	</div>
{/if}
<div class="coin">
	<Coin />
</div>

<style>
	.container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.heading {
		font-family: Pluto;
		font-style: normal;
		font-weight: 900;
		font-size: 1.5rem;
		line-height: 24px;
		text-align: center;
		align-items: center;
		color: #ffffff;
		padding-top: 4rem;
	}

	.container .inner-container {
		display: flex;
		width: 90%;
		height: 75%;
		justify-content: center;
		margin: auto;
	}

	.vote {
		width: 45%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.card {
		width: 50%;
		display: inline-block;
		height: 0;
		padding-bottom: calc(50% * 1.25);
		position: relative;
		min-width: 269px;
		min-height: 338px;
	}

	.card-svg {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.question-wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
	}

	.question {
		padding: 3rem;

		font-family: Pluto;
		font-style: normal;
		font-weight: var(--font-weight);
		font-size: var(--font-size);
		line-height: 24px;

		text-align: center;

		color: #ffffff;
	}

	.btn {
		padding-top: 0.8rem;
		margin-bottom: 0.5rem;
		width: 40%;
		height: 13%;
		min-height: 60px;
		min-width: 200px;
	}

	.list {
		padding-top: 1rem;
		width: 55%;
		display: flex;
		flex-wrap: wrap;
		align-content: baseline;
	}

	.item {
		background: #291d43;
	}

	.picked {
		background: #4c45ac;
	}

	.block {
		cursor: not-allowed;
	}

	li {
		width: 40%;
		display: inline-block;
		height: 0;
		padding-bottom: calc(40% * 0.24);
		min-width: 188px;
		min-height: 45px;
		border-radius: 6px;
		margin-bottom: 1rem;
		margin-left: 1rem;
	}

	.coin {
		position: absolute;
		right: 27px;
		top: 27px;
		z-index: 1;
	}
</style>
